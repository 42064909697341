import { EyeIcon } from "@heroicons/react/20/solid";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import ActionPopup from "../../../shared/ui/components/ActionPopup";
import ClearTableFilters from "../../../shared/ui/components/ClearTableFilters";
import CustomHeaderComponent from "../../../shared/ui/components/CustomHeaderComponent";
import FiltersSelectComponent from "../../../shared/ui/components/FiltersSelectComponent";
import Pill from "../../../shared/ui/components/Pill";
import LoadingTable from "../../../shared/ui/layouts/LoadingTable";
import Dropdown, {
    Option,
} from "../../../shared/ui/layouts/NumberOfElementsDropdown";
import OrdersOverviewBoxesLayout from "../../../shared/ui/layouts/OrdersOverviewBoxesLayout";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import PaginationButtons from "../../../shared/ui/layouts/PaginationButtons";
import { fetchExhibitionsYears } from "../api/fetchExhibitionsYears";
import { OrderDataPropsV2, fetchAllOrdersV2 } from "../api/fetchOrders";
import {
    QuickViewOrderItemProps,
    isCreatingNewOrderAtom,
    quickViewOrderItemAtom,
    selectedOrderAtom,
    selectedOrderMainTaxRateAtom,
} from "../atoms/orders-atoms";
import OrdersQuickViewPopup from "../components/Orders/OrdersPopupActionsContent/OrdersQuickViewPopup";
import { emptyOrder } from "../constants/emptyOrder";
import TableWithTitle from "../layouts/TableWithTitle";

const TABLE_TITLE = "Commandes en cours";

const getPerPageNumber = () => {
  const perPageNumber = localStorage.getItem("perPageNumberOrders");
  return perPageNumber ? parseInt(perPageNumber) : 50;
};

const columnHelper = createColumnHelper<OrderDataPropsV2>();

const OrdersScreenV2 = () => {
  const [selectedOrderMainTaxRate, setSelectedOrderMainTaxRate] =
    useRecoilState(selectedOrderMainTaxRateAtom);
  const [isCreatingNewOrder, setIsCreatingNewOrder] = useRecoilState(
    isCreatingNewOrderAtom
  );
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(
    parseInt(localStorage.getItem("orders-current-page-number") || "1")
  );
  const [perPageNumber, setPerPageNumber] = useState(getPerPageNumber());
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [totalOfPosts, setTotalOfPosts] = useState(0);
  const [orders, setOrders] = useState<OrderDataPropsV2[]>([]);

  const localStorageFiltersKeys = [
    "orders-selected-status",
    "orders-selected-exhibition",
    "orders-selected-year",
    "stands-selected-stands",
    "sorting-orders-column",
  ];
  const resetFilters = () => {
    setSelectedStatus(orderStatusAvailableFilters[0]);
    setSelectedExhibitions(exhibitionsAvailableFilters[0]);
    setSelectedExhibitionYear(exhibitionsYearsAvailableFilters[0]);
    setSelectedStandsAssigned(standsAssignedAvailableFilters[0]);
    setSelectedSortingColumn("");
  };

  const Pagination = () => {
    return (
      <PaginationButtons
        pageNumber={pageNumber}
        isLoading={isLoading}
        setPageNumber={setPageNumber}
        numberOfPages={numberOfPages}
        totalOfItems={totalOfPosts}
      />
    );
  };
  // FILTERS
  // Available filters
  const [orderStatusAvailableFilters, setOrderStatusAvailableFilters] =
    useState<string[]>([
      "Tous les statuts de commande",
      "Attente acceptation",
      "Attente paiement",
      "Inscription payée",
      "Paiement échoué",
      "Salon terminé",
    ]);
  const [exhibitionsAvailableFilters, setExhibitionsAvailableFilters] =
    useState<string[]>([
      "Tous les salons",
      "Bulle",
      "Morges",
      "Aigle",
      "Genève",
    ]);
  const [standsAssignedAvailableFilters, setStandsAssignedAvailableFilters] =
    useState<string[]>(["Toutes les commandes", "Stand non affecté"]);
  const [
    exhibitionsYearsAvailableFilters,
    setExhibitionsYearsAvailableFilters,
  ] = useState<string[]>(["Toutes les années"]);
  // Selected filters indexes
  const [selectedStatus, setSelectedStatus] = useState<string>(
    orderStatusAvailableFilters[0]
  );
  const [selectedExhibitions, setSelectedExhibitions] = useState<string>(
    exhibitionsAvailableFilters[0]
  );
  const [selectedExhibitionYear, setSelectedExhibitionYear] = useState<string>(
    exhibitionsYearsAvailableFilters[0]
  );
  const [selectedStandsAssigned, setSelectedStandsAssigned] = useState<string>(
    standsAssignedAvailableFilters[0]
  );
  const [selectedSortingColumn, setSelectedSortingColumn] =
    useState<string>("");

  const [currentPageNumber, setCurrentPageNumber] = useState(
    localStorage.getItem("orders-current-page-number") || 1
  );

  const updateFiltersBasedOnLocalStorage = () => {
    // Status
    const status = localStorage.getItem("orders-selected-status");
    if (status) {
      setSelectedStatus(status);
    }
    // Exhibition
    const exhibition = localStorage.getItem("orders-selected-exhibition");
    if (exhibition) {
      setSelectedExhibitions(exhibition);
    }
    // Year
    const year = localStorage.getItem("orders-selected-year");
    if (year) {
      setSelectedExhibitionYear(year);
    }
    // Assigned stands
    const standsAssigned = localStorage.getItem("stands-selected-stands");
    if (standsAssigned) {
      setSelectedStandsAssigned(standsAssigned);
    }
    // Sorting column
    const sortingColumn = localStorage.getItem("sorting-orders-column");
    if (sortingColumn) {
      setSelectedSortingColumn(sortingColumn);
    }
  };

  const FilterComponents = () => {
    return (
      <div className="mt-2 flex items-center gap-x-4">
        <ClearTableFilters
          isLoading={isLoading}
          resetFilters={resetFilters}
          localStorageFiltersKeys={localStorageFiltersKeys}
        />
        <FiltersSelectComponent
          isLoading={isLoading}
          options={
            orderStatusAvailableFilters.length > 0
              ? orderStatusAvailableFilters
              : ["Tous les statuts de commande"]
          }
          selected={selectedStatus}
          setSelected={(value, index) => {
            setSelectedStatus(orderStatusAvailableFilters[index]);
            // set local storage
            localStorage.setItem(
              "orders-selected-status",
              orderStatusAvailableFilters[index]
            );
          }}
          type={"status"}
          postType="orders"
        />
        <FiltersSelectComponent
          isLoading={isLoading}
          options={
            exhibitionsYearsAvailableFilters.length > 0
              ? exhibitionsYearsAvailableFilters
              : ["Toutes les années"]
          }
          selected={selectedExhibitionYear}
          setSelected={(value, index) => {
            setSelectedExhibitionYear(exhibitionsYearsAvailableFilters[index]);
            localStorage.setItem(
              "orders-selected-year",
              exhibitionsYearsAvailableFilters[index]
            );
          }}
          type={"year"}
          postType="orders"
        />
        <FiltersSelectComponent
          isLoading={isLoading}
          options={
            exhibitionsAvailableFilters.length > 0
              ? exhibitionsAvailableFilters
              : ["Tous les salons"]
          }
          selected={selectedExhibitions}
          setSelected={(value, index) => {
            setSelectedExhibitions(exhibitionsAvailableFilters[index]);
            localStorage.setItem(
              "orders-selected-exhibition",
              exhibitionsAvailableFilters[index]
            );
          }}
          type={"exhibition"}
          postType="orders"
        />
        <FiltersSelectComponent
          isLoading={isLoading}
          options={
            standsAssignedAvailableFilters.length > 0
              ? standsAssignedAvailableFilters
              : ["Toutes les commandes"]
          }
          selected={selectedStandsAssigned}
          setSelected={(value, index) => {
            setSelectedStandsAssigned(standsAssignedAvailableFilters[index]);
            localStorage.setItem(
              "stands-selected-stands",
              selectedStandsAssigned[index]
            );
          }}
          type={"stands"}
          postType="stands"
        />
      </div>
    );
  };

  const returnNullIfIsDefault = (value: string) => {
    if (
      value === orderStatusAvailableFilters[0] ||
      value === exhibitionsAvailableFilters[0] ||
      value === exhibitionsYearsAvailableFilters[0] ||
      value === standsAssignedAvailableFilters[0] ||
      value === ""
    ) {
      return undefined;
    }
    return value;
  };

  const fetchData = async (pageOverride?: number) => {
    updateFiltersBasedOnLocalStorage();
    let only_assigned = false;
    let sorting_params = "";
    if (localStorage.getItem("stands-selected-stands")) {
      if (
        localStorage.getItem("stands-selected-stands") === "Stand non affecté"
      ) {
        only_assigned = true;
      }
    }
    if (localStorage.getItem("sorting-orders-column")) {
      sorting_params = localStorage.getItem("sorting-orders-column") || "";
    }

    const queryParams = {
      salon_name: returnNullIfIsDefault(
        localStorage.getItem("orders-selected-exhibition") || ""
      ),
      per_page: perPageNumber,
      annee: returnNullIfIsDefault(
        localStorage.getItem("orders-selected-year") || ""
      ),
      order_status: returnNullIfIsDefault(
        localStorage.getItem("orders-selected-status") || ""
      ),
      page: pageOverride || pageNumber + 1,
      only_non_assigned_stands: only_assigned,
      sorting_params: sorting_params,
    };

    setIsLoading(true);
    fetchAllOrdersV2(queryParams)
      .then((data) => {
        // Vérification si pageNumber est supérieur à data.total_pages
        if (pageNumber + 1 > data.total_pages && !pageOverride) {
          setPageNumber(0);
          localStorage.setItem("orders-current-page-number", "0");
          fetchData(1); // Récupérer les données pour la première page
        } else {
          setOrders(data.orders);
          setNumberOfPages(data.total_pages);
          setTotalOfPosts(data.total_count ? data.total_count : 0);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.code.includes("jwt")) {
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setSelectedOrderMainTaxRate(8.1);
    setSelectedOrder(null);
    fetchData();
  }, [
    pageNumber,
    perPageNumber,
    selectedExhibitions,
    selectedExhibitionYear,
    selectedStatus,
    selectedStandsAssigned,
    selectedSortingColumn,
  ]);

  useEffect(() => {
    localStorage.setItem("orders-current-page-number", pageNumber.toString());
  }, [pageNumber]);

  useEffect(() => {
    if (!localStorage.getItem("orders-current-page-number")) {
      setPageNumber(0);
      setNumberOfPages(0);
    }
  }, [
    selectedExhibitions,
    selectedExhibitionYear,
    selectedStatus,
    selectedStandsAssigned,
    selectedSortingColumn,
  ]);

  useEffect(() => {
    fetchExhibitionsYears().then((data) => {
      setExhibitionsYearsAvailableFilters(["Toutes les années", ...data]);
    });
  }, []);

  interface storePerPageNumberProps {
    perPageNumber: number;
    // setter setPerPageNumber
    setPerPageNumber: (value: number) => void;
  }
  const storePerPageNumber = ({
    perPageNumber,
    setPerPageNumber,
  }: storePerPageNumberProps) => {
    localStorage.setItem("perPageNumberOrders", perPageNumber.toString());
    setPerPageNumber(perPageNumber);
  };

  const options: Option[] = [
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "200", value: 200 },
    { label: "500", value: 500 },
  ];
  const defaultOption: Option =
    options.find((option) => option.value === perPageNumber) || options[0];
  const handleDropdownChange = (selectedOption: Option) => {
    setPerPageNumber(selectedOption.value);
    storePerPageNumber({
      setPerPageNumber,
      perPageNumber: selectedOption.value,
    });
    setPageNumber(0);
    setNumberOfPages(0);
  };

  const [quickViewOrderItem, setQuickViewOrderItem] = useRecoilState(
    quickViewOrderItemAtom
  );

  const columns = [
    columnHelper.accessor("order_id", {
      header: () => (
        <CustomHeaderComponent
          title="Numéro"
          sortName="orderId"
          post="orders"
          selectedSortingColumn={selectedSortingColumn}
          setSelectedSortingColumn={setSelectedSortingColumn}
        />
      ),
      cell: ({ getValue, row }) => (
        <div className="flex items-center justify-center gap-x-2">
          <Link
            className="underline"
            to={`/commandes/${row.original.order_id}`}
            onClick={() => {}}
          >
            {row.original.order_id || "-"}
          </Link>
          <div
            className="hover:cursor-pointer"
            onClick={() => {
              const quickViewOrderItem: QuickViewOrderItemProps = {
                company: row.original.billing_company_name || "",
                firstName: row.original.billing_first_name || "",
                lastName: row.original.billing_last_name || "",
                email: row.original.billing_email || "",
                phone: row.original.billing_phone || "",
                website: row.original.user_website || "",
                activityType: row.original.activityType || "",
                haveAlreadyParticipated:
                  row.original.has_been_to_exhibition || false,
              };
              setQuickViewOrderItem(quickViewOrderItem);
            }}
          >
            <EyeIcon className="h-4 w-4" />
          </div>
        </div>
      ),
    }),
    columnHelper.accessor("billing_company_name", {
      header: () => (
        <CustomHeaderComponent
          title="Client"
          sortName="billingCompanyName"
          post="orders"
          selectedSortingColumn={selectedSortingColumn}
          setSelectedSortingColumn={setSelectedSortingColumn}
        />
      ),
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor("order_date", {
      header: () => (
        <CustomHeaderComponent
          title="Date"
          sortName="orderId"
          post="orders"
          selectedSortingColumn={selectedSortingColumn}
          setSelectedSortingColumn={setSelectedSortingColumn}
        />
      ),
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor("order_status", {
      header: "Statut",
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor("order_total", {
      header: () => (
        <CustomHeaderComponent
          title="Total"
          sortName="orderTotal"
          post="orders"
          selectedSortingColumn={selectedSortingColumn}
          setSelectedSortingColumn={setSelectedSortingColumn}
        />
      ),
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor("numero_de_stand", {
      header: () => (
        <CustomHeaderComponent
          title="N° Stand"
          sortName="standNumber"
          post="orders"
          selectedSortingColumn={selectedSortingColumn}
          setSelectedSortingColumn={setSelectedSortingColumn}
        />
      ),
      cell: ({ getValue }) => (
        <span className={`${getValue() !== "-" && "font-bold"}`}>
          {getValue()}
        </span>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor("exhibition_name", {
      header: "Salon",
      cell: ({ getValue, row }) => (
        <Pill
          label={getValue()}
          bgColor={
            row.original.exhibition_primary_color !== ""
              ? row.original.exhibition_primary_color
              : "#737373"
          }
          textColor={"#fff"}
        />
      ),
      enableSorting: false,
    }),
  ];

  const popup: JSX.Element[] = [
    <ActionPopup
      forQuickView={true}
      key={Math.random()}
      title={`${
        quickViewOrderItem ? quickViewOrderItem.company : "Aperçu fiche client"
      }`}
      children={
        <OrdersQuickViewPopup
          activityType={quickViewOrderItem?.activityType ?? ""}
          company={quickViewOrderItem?.company ?? ""}
          email={quickViewOrderItem?.email ?? ""}
          firstName={quickViewOrderItem?.firstName ?? ""}
          haveAlreadyParticipated={
            quickViewOrderItem?.haveAlreadyParticipated ?? false
          }
          lastName={quickViewOrderItem?.lastName ?? ""}
          phone={quickViewOrderItem?.phone ?? ""}
          website={quickViewOrderItem?.website ?? ""}
        />
      }
      isVisible={quickViewOrderItem !== null}
      // isVisible={true}
      setIsVisible={() => setQuickViewOrderItem(null)}
      action={() => {}}
      cancelAction={() => {
        setQuickViewOrderItem(null);
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Ajouter"
      actionButtonsVisible={false}
    />,
  ];

  return (
    <PageLayout>
      {popup}
      <div id="orders">
        <div className="flex items-center justify-end">
          <Link
            className="flex flex-row items-center gap-x-4 rounded border-none bg-[#14b8a6] px-4 py-2 text-lg text-gray-50 shadow"
            to={`/commandes/nouvelle-commande`}
            onClick={() => {
              // Scroll to top
              setIsCreatingNewOrder(true);
              setSelectedOrder(emptyOrder);
              window.scrollTo(0, 0);
            }}
          >
            Ajouter une commande
          </Link>
        </div>
        <OrdersOverviewBoxesLayout />
      </div>
      {isLoading && (
        <LoadingTable
          title={TABLE_TITLE}
          filtersComponents={<FilterComponents />}
          columns={columns}
          data={[{}, {}, {}, {}, {}, {}, {}]}
          numberOfItems={50}
          paginationButtons={<Pagination />}
          dropdown={
            <Dropdown
              options={options}
              onChange={handleDropdownChange}
              defaultValue={defaultOption}
            />
          }
        />
      )}
      {!isLoading && (
        <div className="flex-end">
          <TableWithTitle
            filtersComponents={<FilterComponents />}
            isLoading={isLoading}
            title={TABLE_TITLE}
            tableData={orders}
            tableColumns={columns}
            enableSorting={true}
            paginationButtons={<Pagination />}
            dropdown={
              <Dropdown
                options={options}
                onChange={handleDropdownChange}
                defaultValue={defaultOption}
              />
            }
          />
        </div>
      )}
      {/* Pagination buttons with numbers */}
      <div className="mt-10">
        <Pagination />
      </div>
      <p className="text-right text-[#cbd5e1]">v. 2.0.21</p>
    </PageLayout>
  );
};

export default OrdersScreenV2;
