import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";

type FormValues = {
  nom: string;
  prenom: string;
  entreprise: string;
  telephone: string;
  email: string;
  siteInternet: string;
  password: string;
  passwordConfirm: string;
  isProfessional: boolean;
};

const resolver: Resolver<FormValues> = async (values) => {
  return {
    values: values,
    errors: {
      email: {
        type: "required",
        message: "Champs requis",
      },
      password: {
        type: "required",
        message: "Champs requis",
      },
      passwordConfirm: {
        type: "required",
        message: "Champs requis",
      },
    },
  };
};
interface CreateAccountFunctionProps {
  isCreatingAccount: boolean;
  setIsCreatingAccount: (value: boolean) => void;
  accountCreated: boolean;
  setAccountCreated: (value: boolean) => void;
  nom: string;
  prenom: string;
  entreprise: string;
  telephone: string;
  email: string;
  siteInternet: string;
  password: string;
  passwordConfirm: string;
  isProfessional: boolean;
}

interface AddAccountPopupProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  roles?: string[];
  user?: any;
}
export const AddAccountPopup = ({
  isOpen,
  setIsOpen,
}: AddAccountPopupProps) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm<FormValues>({ resolver });

  const [showPassord, setShowPassword] = useState(false);
  const [showPassordConfirm, setShowPasswordConfirm] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [showPopupAlreadyExists, setShowPopupAlreadyExists] = useState(false);
  const [userRole, setUserRole] = useState([""]);
  const [user, setUser] = useState({});
  // let [isOpen, setIsOpen] = useState(isPopupOpen);
  const navigate = useNavigate();

  const isDisabled = () => {
    if (watch("email") && watch("password") && watch("passwordConfirm")) {
      return false;
    }
    return true;
  };

  function closeModal() {
    if (!showPopupAlreadyExists) setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const createAccount = ({
    isCreatingAccount,
    setIsCreatingAccount,
    accountCreated,
    setAccountCreated,
    nom,
    prenom,
    entreprise,
    telephone,
    email,
    siteInternet,
    password,
    passwordConfirm,
    isProfessional,
  }: CreateAccountFunctionProps) => {
    let token = "";
    if (localStorage.getItem("token-dashboard")) {
      token = JSON.parse(localStorage.getItem("token-dashboard") as string);
    }

    console.log("isProfessional", isProfessional);

    setIsCreatingAccount(true);
    axios
      .put(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/exhibitors`,
        {
          nom,
          prenom,
          entreprise,
          telephone,
          email,
          siteInternet,
          password,
          passwordConfirm,
          isProfessional: isProfessional ? true : false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setIsCreatingAccount(false);
        setAccountCreated(true);
        setTimeout(() => {
          setAccountCreated(false);
        }, 2000);
        setTimeout(() => {
          navigate(`/clients/${res.data}`);
        }, 1000);
      })
      .catch((err) => {
        setUser(err.response.data.data.user);
        setUserRole(err.response.data.data.roles);
        setShowPopupAlreadyExists(true);

        console.log(err.response.data.data.roles);
        // Check if the message includes 'already exists'
        setIsCreatingAccount(false);
        setAccountCreated(false);
        // if (err.response.data.message.includes("already exists")) {
        //   setIsErrorOpen(true);
        // }
      })
      .finally(() => {
        // setIsCreatingAccount(false);
        isCreatingAccount && setIsCreatingAccount(false);
        accountCreated && setAccountCreated(false);
      });
  };

  useEffect(() => {
    console.log("userRole", userRole);
  }, [userRole]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-medium leading-6 text-gray-900"
                  >
                    Ajout d'un compte
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Renseignez ici les informations du compte à créer. Seuls
                      les champs marqués d'un{" "}
                      <span className="text-red-400">*</span> sont obligatoires.
                    </p>
                    <form className="mt-4 flex flex-col gap-4">
                      <div
                        id="professionnel"
                        className="flex flex-row items-start justify-between gap-4"
                      >
                        <div id="is_professional" className="flex w-full flex-row gap-2">
                          <input
                            type="checkbox"
                            className="w-5"
                            placeholder="isProfessional"
                            {...register("isProfessional")}
                            checked={watch("isProfessional")}
                          />
                          <label>Ce compte est-il dédié à un professionnel ?</label>
                        </div>
                      </div>
                      <div
                        id="nom_prenom"
                        className="flex flex-row items-start justify-between gap-4"
                      >
                        <div id="nom" className="flex w-full flex-col gap-1">
                          <label>Nom</label>
                          <input
                            type="text"
                            className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                            placeholder="Nom"
                            {...register("nom")}
                          />
                          {errors?.nom && <p>{errors.nom.message}</p>}
                        </div>
                        <div id="prenom" className="flex w-full flex-col gap-1">
                          <label>Prénom</label>
                          <input
                            type="text"
                            className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                            placeholder="Prénom"
                            {...register("prenom")}
                          />
                          {errors?.prenom && <p>{errors.prenom.message}</p>}
                        </div>
                      </div>
                      <div
                        id="entreprise_telephone"
                        className="flex flex-row items-start justify-between gap-4"
                      >
                        <div
                          id="entreprise"
                          className="flex w-full flex-col gap-1"
                        >
                          <label>Entreprise</label>
                          <input
                            type="text"
                            className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                            placeholder="Entreprise"
                            {...register("entreprise")}
                          />
                          {errors?.entreprise && (
                            <p>{errors.entreprise.message}</p>
                          )}
                        </div>
                        <div
                          id="telephone"
                          className="flex w-full flex-col gap-1"
                        >
                          <label>Téléphone</label>
                          <input
                            type="tel"
                            className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                            placeholder="Téléphone"
                            {...register("telephone")}
                          />
                          {errors?.telephone && (
                            <p>{errors.telephone.message}</p>
                          )}
                        </div>
                      </div>
                      <div
                        id="email_siteInternet"
                        className="flex flex-row items-start justify-between gap-4"
                      >
                        <div id="email" className="flex w-full flex-col gap-1">
                          <label>
                            Email <span className="text-red-400">*</span>{" "}
                          </label>
                          <input
                            type="email"
                            className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                            placeholder="Email"
                            {...register("email")}
                          />
                          {errors?.email?.type === "required" &&
                            !watch("email") && <p>{errors.email.message}</p>}
                        </div>
                        <div
                          id="siteInternet"
                          className="flex w-full flex-col gap-1"
                        >
                          <label>Site internet</label>
                          <input
                            type="url"
                            className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                            placeholder="Site internet"
                            {...register("siteInternet")}
                          />
                          {errors?.siteInternet && (
                            <p>{errors.siteInternet.message}</p>
                          )}
                        </div>
                      </div>
                      <div
                        id="password"
                        className="flex flex-row items-start justify-between gap-4"
                      >
                        <div
                          id="password"
                          className="flex w-full flex-col gap-1"
                        >
                          <label>
                            <div className="flex flex-row items-center">
                              <div className="">
                                <span>Mot de passe </span>
                                <span className="text-red-400">*</span>
                              </div>
                              <span
                                className="ml-2 cursor-pointer"
                                onClick={() => setShowPassword(!showPassord)}
                              >
                                {!showPassord ? (
                                  <EyeIcon className="h-5 w-5" />
                                ) : (
                                  <EyeSlashIcon className="h-5 w-5" />
                                )}
                              </span>
                            </div>
                          </label>
                          <input
                            type={showPassord ? "text" : "password"}
                            className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                            placeholder="Mot de passe"
                            {...register("password")}
                          />
                          {errors?.password?.type === "required" &&
                            !watch("password") && (
                              <p>{errors.password.message}</p>
                            )}
                        </div>
                        <div
                          id="passwordConfirmation"
                          className="flex w-full flex-col gap-1"
                        >
                          <label>
                            <div className="flex flex-row items-center">
                              <div className="">
                                <span>Confirmation mot de passe </span>
                                <span className="text-red-400">*</span>
                              </div>
                              <span
                                className="ml-2 cursor-pointer"
                                onClick={() =>
                                  setShowPasswordConfirm(!showPassordConfirm)
                                }
                              >
                                {!showPassordConfirm ? (
                                  <EyeIcon className="h-5 w-5" />
                                ) : (
                                  <EyeSlashIcon className="h-5 w-5" />
                                )}
                              </span>
                            </div>
                          </label>
                          <input
                            type={showPassordConfirm ? "text" : "password"}
                            className="block w-full rounded-md border border-gray-300 px-2 py-2 shadow-sm focus:border-[#14B8A6] focus:ring-[#14B8A6] sm:text-sm"
                            placeholder="Confirmation mot de passe"
                            {...register("passwordConfirm")}
                          />
                          {errors?.passwordConfirm?.type === "required" &&
                            !watch("passwordConfirm") && (
                              <p>{errors.passwordConfirm.message}</p>
                            )}
                        </div>
                      </div>
                      {watch("passwordConfirm") &&
                        watch("password") &&
                        watch("password") !== watch("passwordConfirm") && (
                          <span>Les mots de passe ne correspondent pas</span>
                        )}
                      <div>
                        <div className="mt-4 flex items-center gap-4">
                          <button
                            type="button"
                            className="w-full justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 shadow transition-all hover:bg-red-200 hover:shadow-none focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                            onClick={closeModal}
                          >
                            Annuler
                          </button>
                          <span
                            // disabled={isDisabled()}
                            // type="submit"
                            onClick={() => {
                              if (isDisabled()) {
                                return;
                              }
                              createAccount({
                                isCreatingAccount,
                                setIsCreatingAccount,
                                accountCreated,
                                setAccountCreated,
                                nom: watch("nom"),
                                prenom: watch("prenom"),
                                entreprise: watch("entreprise"),
                                telephone: watch("telephone"),
                                email: watch("email"),
                                siteInternet: watch("siteInternet"),
                                password: watch("password"),
                                passwordConfirm: watch("passwordConfirm"),
                                isProfessional: watch("isProfessional"),
                              });
                            }}
                            className={`flex w-full select-none items-center justify-center rounded-md border border-transparent text-center text-white shadow transition-all hover:shadow-none ${
                              isDisabled() ||
                              isCreatingAccount ||
                              accountCreated
                                ? "cursor-not-allowed opacity-40"
                                : "cursor-pointer hover:bg-[#0F766E]"
                            } bg-[#14B8A6] px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2`}
                          >
                            {!isCreatingAccount &&
                              !accountCreated &&
                              "Ajouter le compte"}
                            {isCreatingAccount && "Ajout en cours..."}
                            {accountCreated && (
                              <span className="flex flex-row items-center gap-4">
                                Compte ajouté{" "}
                                <CheckCircleIcon className="w-5" />
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <AccountAllreadyExists
        isOpen={showPopupAlreadyExists}
        setIsOpen={setShowPopupAlreadyExists}
        roles={userRole}
        user={user}
      />
    </>
  );
};

export const AccountAllreadyExists = ({
  isOpen,
  setIsOpen,
  roles,
  user,
}: AddAccountPopupProps) => {
  const navigate = useNavigate();

  const [isConvertingAccount, setIsConvertingAccount] = useState(false);
  const [accountConverted, setAccountConverted] = useState(false);
  const isProfessional = roles && roles.includes("professionnel");

  useEffect(() => {
    console.log("user", user);
  }, [user]);

  const ConvertAccount = () => {
    let token = "";
    if (localStorage.getItem("token-dashboard")) {
      token = JSON.parse(localStorage.getItem("token-dashboard") as string);
    }
    setIsConvertingAccount(true);
    axios
      .put(
        `${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/convert-exhibitors`,
        {
          id: user.ID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setIsConvertingAccount(false);
        setAccountConverted(true);
        setTimeout(() => {
          setAccountConverted(false);
        }, 2000);
        setTimeout(() => {
          navigate(`/clients/${res.data}`);
        }, 1000);
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        // setIsCreatingAccount(false);
        isConvertingAccount && setIsConvertingAccount(false);
        accountConverted && setAccountConverted(false);
      });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-xl font-medium leading-6">
                  {isProfessional ? (
                    <>Un compte professionnel existe déjà avec cet email</>
                  ) : (
                    <>Un compte existe déjà avec cet email</>
                  )}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Un compte existe déjà avec cette adresse email.{" "}
                    {isProfessional ? (
                      <p>
                        Ce compte est un compte professionnel. Veuillez utiliser
                        la barre de recherche pour le retrouver.
                      </p>
                    ) : (
                      <p>
                        Voulez vous convertir ce compte en compte professionnel
                        ? Le mot de passe saisi ne sera pas celui du compte
                        existant.
                      </p>
                    )}
                  </p>
                </div>

                {!isProfessional && (
                  <div className="mt-4 flex items-center gap-4">
                    <button
                      type="button"
                      className="w-full justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 shadow transition-all hover:bg-red-200 hover:shadow-none focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Annuler
                    </button>
                    <button
                      // disabled={isDisabled()}
                      // type="submit"
                      onClick={() => {
                        ConvertAccount();
                      }}
                      className={`flex w-full select-none items-center justify-center rounded-md border border-transparent bg-[#14B8A6] px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2`}
                    >
                      {!isConvertingAccount &&
                        !accountConverted &&
                        "Ajouter le compte"}
                      {isConvertingAccount && "Ajout en cours..."}
                      {accountConverted && (
                        <span className="flex flex-row items-center gap-4">
                          Compte ajouté <CheckCircleIcon className="w-5" />
                        </span>
                      )}
                    </button>
                  </div>
                )}
                {isProfessional && (
                  <div className="mt-4 flex items-center gap-4">
                    <button
                      type="button"
                      className="w-full justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 shadow transition-all hover:bg-red-200 hover:shadow-none focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Ok
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
