import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { ConferenceDataPropsV2 } from "../../../features/conferences/api/fetchConferences";
import { quickViewOrderItemAtom } from "../../../features/orders/atoms/orders-atoms";
import OrdersQuickViewPopup from "../../../features/orders/components/Orders/OrdersPopupActionsContent/OrdersQuickViewPopup";
import TableWithTitle from "../../../features/orders/layouts/TableWithTitle";
import { CPT } from "../../types/sharedTypes";
import ActionPopup from "../components/ActionPopup";
import LoadingTable from "./LoadingTable";
import Dropdown, { Option } from "./NumberOfElementsDropdown";
import PageLayout from "./PageLayout";
import PaginationButtons from "./PaginationButtons";

const TableLayout: React.FC<CPT> = ({
  tableTitle,
  localStorageData,
  columns,
  updateFiltersBasedOnLocalStorage,
  filterComponents,
  filters,
  fetchingFunction,
  type,
  overviewBoxes,
}) => {
  const getPerPageNumber = () => {
    const perPageNumber = localStorage.getItem(
      localStorageData.perPageNumberLocalStorageName
    );
    return perPageNumber ? parseInt(perPageNumber) : 50;
  };

  const [currentStatus, setCurrentStatus] = useState(
    localStorage.getItem(localStorageData.selectedStatus) || ""
  );
  const [currentYear, setCurrentYear] = useState(
    localStorage.getItem(localStorageData.selectedYear) || ""
  );
  const [currentExhibition, setCurrentExhibition] = useState(
    localStorage.getItem(localStorageData.selectedExhibition) || ""
  );
  const [currentStand, setCurrentStand] = useState(
    localStorageData.selectedStand
      ? localStorage.getItem(localStorageData.selectedStand) || ""
      : ""
  );
  const [currentSorting, setCurrentSorting] = useState(
    localStorageData.sortingColumn
      ? localStorage.getItem(localStorageData.sortingColumn) || ""
      : ""
  );

  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(
    parseInt(
      localStorage.getItem(
        localStorageData.currentPageNumberLocalStorageName
      ) || "0"
    )
  );
  const [perPageNumber, setPerPageNumber] = useState(getPerPageNumber());
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [totalOfPosts, setTotalOfPosts] = useState(0);
  const [posts, setPosts] = useState<ConferenceDataPropsV2[]>([]);

  // FILTERS
  // Available filters
  const orderStatusAvailableFilters = filters.availableStatusFilters;
  const exhibitionsAvailableFilters = filters.availableExhibitionsFilters;
  const exhibitionsYearsAvailableFilters = filters.availableYearsFilters;

  const [quickViewOrderItem, setQuickViewOrderItem] = useRecoilState(
    quickViewOrderItemAtom
  );

  const Pagination = () => {
    return (
      <PaginationButtons
        pageNumber={pageNumber}
        isLoading={isLoading}
        setPageNumber={setPageNumber}
        numberOfPages={numberOfPages}
        totalOfItems={totalOfPosts}
      />
    );
  };

  const returnNullIfIsDefault = (value: string) => {
    if (
      value === orderStatusAvailableFilters[0] ||
      value === exhibitionsAvailableFilters[0] ||
      value === exhibitionsYearsAvailableFilters[0] ||
      // value === standsAssignedAvailableFilters[0] ||
      value === ""
    ) {
      return undefined;
    }
    return value;
  };

  const handleFetchData = async (pageOverride?: number) => {
    updateFiltersBasedOnLocalStorage();
    const currentPage = pageOverride ?? pageNumber;
    let only_assigned = false; // ou définissez cette valeur en fonction de vos besoins

    const queryParams = {
      post_name: returnNullIfIsDefault(
        localStorage.getItem(localStorageData.selectedExhibition) || ""
      ),
      per_page: perPageNumber,
      annee: returnNullIfIsDefault(
        localStorage.getItem(localStorageData.selectedYear) || ""
      ),
      post_status: returnNullIfIsDefault(
        localStorage.getItem(localStorageData.selectedStatus) || ""
      ),
      standType: returnNullIfIsDefault(
        localStorageData.selectedStand
          ? localStorage.getItem(localStorageData.selectedStand) || ""
          : ""
      ),
      sorting_params: returnNullIfIsDefault(
        localStorageData.sortingColumn
          ? localStorage.getItem(localStorageData.sortingColumn) || ""
          : ""
      ),
      page: currentPage + 1,
      only_non_assigned_stands: only_assigned,
    };

    console.log("queryParams ===> ", queryParams);

    setIsLoading(true);
    fetchingFunction(queryParams)
      .then((data) => {
        console.log("Post full data => ", data);
        if (!data.posts) {
          console.error("data.conferences is undefined:", data);
          return;
        }
        if (currentPage + 1 > data.total_pages && !pageOverride) {
          setPageNumber(0);
          localStorage.setItem(
            localStorageData.currentPageNumberLocalStorageName,
            "0"
          );
          handleFetchData(1);
        } else {
          setPosts(data.posts);
          setNumberOfPages(data.total_pages);
          setTotalOfPosts(data.total_count ? data.total_count : 0);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.code.includes("jwt")) {
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, [
    pageNumber,
    perPageNumber,
    localStorage.getItem(localStorageData.selectedExhibition),
    localStorage.getItem(localStorageData.selectedStatus),
    localStorage.getItem(localStorageData.selectedYear),
    localStorageData.selectedStand &&
      localStorage.getItem(localStorageData.selectedStand),
    localStorageData.sortingColumn &&
      localStorage.getItem(localStorageData.sortingColumn),
  ]);

  useEffect(() => {
    localStorage.setItem(
      localStorageData.currentPageNumberLocalStorageName,
      pageNumber.toString()
    );
  }, [pageNumber, localStorageData.currentPageNumberLocalStorageName]);

  useEffect(() => {
    if (
      !localStorage.getItem(localStorageData.currentPageNumberLocalStorageName)
    ) {
      setPageNumber(0);
      setNumberOfPages(0);
    }
  }, [localStorageData, localStorageData.currentPageNumberLocalStorageName]);

  interface storePerPageNumberProps {
    perPageNumber: number;
    // setter setPerPageNumber
    setPerPageNumber: (value: number) => void;
  }
  const storePerPageNumber = ({
    perPageNumber,
    setPerPageNumber,
  }: storePerPageNumberProps) => {
    localStorage.setItem(
      localStorageData.perPageNumberLocalStorageName,
      perPageNumber.toString()
    );
    setPerPageNumber(perPageNumber);
  };

  const options: Option[] = [
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "200", value: 200 },
    { label: "500", value: 500 },
  ];
  const defaultOption: Option =
    options.find((option) => option.value === perPageNumber) || options[0];

  const handleDropdownChange = (selectedOption: Option) => {
    setPerPageNumber(selectedOption.value);
    storePerPageNumber({
      setPerPageNumber,
      perPageNumber: selectedOption.value,
    });
    setPageNumber(0);
    setNumberOfPages(0);
  };

  const popup: JSX.Element[] = [
    <ActionPopup
      forQuickView={true}
      key={Math.random()}
      title={`${
        quickViewOrderItem ? quickViewOrderItem.company : "Aperçu fiche client"
      }`}
      children={
        <OrdersQuickViewPopup
          activityType={quickViewOrderItem?.activityType ?? ""}
          company={quickViewOrderItem?.company ?? ""}
          email={quickViewOrderItem?.email ?? ""}
          firstName={quickViewOrderItem?.firstName ?? ""}
          haveAlreadyParticipated={
            quickViewOrderItem?.haveAlreadyParticipated ?? false
          }
          lastName={quickViewOrderItem?.lastName ?? ""}
          phone={quickViewOrderItem?.phone ?? ""}
          website={quickViewOrderItem?.website ?? ""}
        />
      }
      isVisible={quickViewOrderItem !== null}
      // isVisible={true}
      setIsVisible={() => setQuickViewOrderItem(null)}
      action={() => {}}
      cancelAction={() => {
        setQuickViewOrderItem(null);
      }}
      actionButtonDisabled={false}
      actionButtonTitle="Ajouter"
      actionButtonsVisible={false}
    />,
  ];

  useEffect(() => {
    // Vérifiez si les valeurs des filtres ont changé
    const statusChanged =
      currentStatus !== localStorage.getItem(localStorageData.selectedStatus);
    const yearChanged =
      currentYear !== localStorage.getItem(localStorageData.selectedYear);
    const exhibitionChanged =
      currentExhibition !==
      localStorage.getItem(localStorageData.selectedExhibition);
    const standChanged =
      localStorageData.selectedStand &&
      currentStand !== localStorage.getItem(localStorageData.selectedStand);
    const sortingChanged =
      localStorageData.sortingColumn &&
      currentSorting !== localStorage.getItem(localStorageData.sortingColumn);

    console.log(
      "localStorageData.selectedSorting",
      localStorageData.sortingColumn
    );

    if (sortingChanged) {
      console.log("sortingChanged");
    } else {
      console.log("sorting not changed");
    }

    if (
      statusChanged ||
      yearChanged ||
      exhibitionChanged ||
      standChanged ||
      sortingChanged
    ) {
      setPageNumber(0);
      setCurrentStatus(
        localStorage.getItem(localStorageData.selectedStatus) || ""
      );
      setCurrentYear(localStorage.getItem(localStorageData.selectedYear) || "");
      setCurrentExhibition(
        localStorage.getItem(localStorageData.selectedExhibition) || ""
      );
      setCurrentStand(
        localStorageData.selectedStand
          ? localStorage.getItem(localStorageData.selectedStand) || ""
          : ""
      );
      setCurrentSorting(
        localStorageData.sortingColumn
          ? localStorage.getItem(localStorageData.sortingColumn) || ""
          : ""
      );
      handleFetchData(0);
    } else {
      handleFetchData();
    }
  }, [
    perPageNumber,
    localStorage.getItem(localStorageData.selectedExhibition),
    localStorage.getItem(localStorageData.selectedStatus),
    localStorage.getItem(localStorageData.selectedYear),
    localStorageData.selectedStand &&
      localStorage.getItem(localStorageData.selectedStand),
    localStorageData.sortingColumn &&
      localStorage.getItem(localStorageData.sortingColumn),
  ]);

  return (
    <PageLayout>
      {popup}
      <div id={type}>
        {/* <OrdersOverviewBoxesLayout /> */}
        {overviewBoxes}
      </div>
      {isLoading && (
        <LoadingTable
          title={tableTitle}
          filtersComponents={filterComponents}
          columns={columns}
          data={columns.map(() => {
            return {};
          })}
          numberOfItems={50}
          paginationButtons={<Pagination />}
          dropdown={
            <Dropdown
              options={options}
              onChange={handleDropdownChange}
              defaultValue={defaultOption}
            />
          }
        />
      )}
      {!isLoading && (
        <div className="flex-end">
          <TableWithTitle
            filtersComponents={filterComponents}
            isLoading={isLoading}
            title={tableTitle}
            tableData={posts}
            tableColumns={columns}
            enableSorting={true}
            paginationButtons={<Pagination />}
            dropdown={
              <Dropdown
                options={options}
                onChange={handleDropdownChange}
                defaultValue={defaultOption}
              />
            }
          />
        </div>
      )}
      {/* Pagination buttons with numbers */}
      <div className="mt-10">{<Pagination />}</div>
      <p className="text-right text-[#cbd5e1]">v. 2.0.21</p>
    </PageLayout>
  );
};

export default TableLayout;
